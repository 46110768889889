<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>{{title}} - Ticket</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Tickets
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="Enroller Name" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="Enrolment key" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field label="Reference Number" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn
                      type="submit"
                      class="mt-1 p-2 btn btn-block btn-primary"
                      style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn
                    class="mt-1 btn btn-block btn-secondary"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-power-off</v-icon>&nbsp;
                    Reset
                  </v-btn>
                </v-col>
              </div>
            </div>

            <v-row>
              <v-col cols="9"></v-col>
              <v-col cols="3">
                <div class="d-flex justify-space-between">
                  <v-autocomplete
                      outlined
                      :items="available_actions"
                      label="Select action"
                      item-text="text"
                      item-value="value"
                      v-model="bulk_action"
                      dense
                  >
                  </v-autocomplete>

                  <v-btn class="btn btn-primary ml-4" style="color: #fff" @click="performBulkAction" :loading="bulkActionLoading">
                    Go
                    <v-icon small elevation="2" outlined class="ml-2">fas fa-arrow-right</v-icon>&nbsp;
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Enroller Name</th>
                  <th class="px-3">Enrolment key</th>
                  <th class="px-3">Exam name</th>
                  <th class="px-3">Ticket Type</th>
                  <th class="px-3">Requested On</th>
                  <th class="px-3">Additional Info</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="item in tickets">
                    <td class="px-2">
                      <a class=""
                      >{{item.title}}</a>
                    </td>
                    <td>
                     <span class="text-dark-75 font-weight-bolder d-block font-size-lg"
                     >{{item.examkey}}</span>
                    </td>
                    <td>
                     <span class="text-dark-75 font-weight-bolder d-block font-size-lg"
                     >lorem ipsum lliren shue hshd</span>
                    </td>

                    <td>
                      <span class=""
                      >{{item.support_type_text}}</span>
                    </td>
                    <td>
                     <span class=""
                     >{{item.created_at}}</span>
                    </td>
                    <td>
                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                        cart thing
                      </span>
                    </td>
                    <td>
                        <span class="label label-lg label-inline"
                              v-bind:class="`label-light-success`"
                        >
                          {{item.status_text}}
                        </span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editTicket" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon>fas fa-edit</v-icon>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <v-icon>fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import TicketService from "@/services/ticket/TicketService";
const ticketService = new TicketService();

export default {
  data(){
    return{
      tickets: [],
      title: '',
      search:{
        title: ''
      },
      available_actions:[
        {text: 'Mark as read', value: 'mark_as_read'},
      ],
      bulkActionLoading: false,
      bulk_action:''
    }
  },
  methods: {
    editTicket(){

    },
    getTickets(support_type){
      ticketService
      .paginate(this.search)
      .then((response) => {
        this.tickets = response.data.data;
      })
      .catch((error) => {

      })
    }
  },
  mounted(){
    this.getTickets(this.support_type);
    if(this.supportType == 'general')
      this.title = 'General';

    if(this.supportType == 'refund')
      this.title = 'Refund';
  },
  computed:{
    supportType(){
      return this.$route.params.support_type;
    }
  }
}
</script>
